import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import editor from "../../Assets/Projects/codeEditor.png";
import terminal from "../../Assets/Projects/terminal.png";
import uptime from "../../Assets/Projects/uptime.png";
import page from "../../Assets/Projects/page.png";
import whoogle from "../../Assets/Projects/whoogle.png";
import todo from "../../Assets/Projects/todo.png";
import quote from "../../Assets/Projects/quote.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently. I personally host all my project including this website on a server that i own. Thanks for your support! 
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Links"
              description="A simple links website where you can find my e-mail, instagram, blog, Photography website, Web services uptime and my portfolio (this website). This project has just started and is under developement. Some information may not be correct and you may experience some issues, i am currently working on it."
              ghLink="https://github.com"
              demoLink="https://links.neevchandiramani.com/"              
            />
          </Col>
        
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={uptime}
              isBlog={false}
              title="Server monitoring"
              description="Just a basic monitoring tool. I use it to monitor my websites. I use the tool uptime-Kuma, you can find the documentation link under this card."
              ghLink="https://github.com/louislam/uptime-kuma/wiki"
              demoLink="https://uptime.nchandir.com/status/neevchandiramani"              
            />
          </Col>
         
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={terminal}
              isBlog={false}
              title="Fake terminal"
              description="This project is a fake terminal website. I support this project and you should consider go checking it out."
              ghLink="https://github.com/luisbraganca/fake-terminal-website"
              demoLink="https://luisbraganca.github.io/fake-terminal-website/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={whoogle}
              isBlog={false}
              title="Self hosted Search Engine"
              description="This project is a self hosted search engine. It allows you to get Google search results, but without any ads, javascript, AMP links, cookies, or IP address tracking. You can try it or use it instead of Google with the demo link. You can also host it yourself!"
              ghLink="https://github.com/benbusby/whoogle-search"
              demoLink="https://searchengine.neevchandiramani.com"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={page}
              isBlog={false}
              title="404 Page"
              description="This project is just a funny 404 page. You will probably find this page during your exploration of this website. This link is hidden and it is your turn to find it. If you can't find the page i'll keep the link down (demo) for you."
              ghLink="https://github.com/Lotustrailtheprestigefig/404-not-found/"
              demoLink="https://404.neevchandiramani.com"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={todo}
              isBlog={false}
              title="To-Do List"
              description="This project is a simple to-do list. You can add, remove and mark tasks as done. This project is under developement and some features may not work as expected. I am currently working on it. You can try it out with the demo link."
              ghLink="https://github.com/NeevChandiramani/to-do-list-web?tab=readme-ov-file"
              demoLink="https://todo.neevchandiramani.com"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={quote}
              isBlog={false}
              title="Today's Quote"
              description="This project displays a daily quote to the user. It also shows the current date and time. It is a simple project that i made to learn how to use APIs. You can try it out with the demo link."
              ghLink="https://github.com/NeevChandiramani/quote-of-the-day"
              demoLink="https://quote.neevchandiramani.com"              
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
